import { createBrowserRouter } from 'react-router-dom';
import BaseLayout from './BaseLayout';
import HomePage from './Home';
import LoginPage from './login/LoginPage';
import SignupPage from './signup/SignUpPage';
import TermsPage from './signup/TermsPage';
import ResetPasswordPage from './ResetPassword';
import DashboardPage from './Dashboard';
import AboutPage from './About';
import ContactPage from './Contact';
import eventRoutes from './events/EventRoutes';
import gameRoutes from './games/GameRoutes';
import adminRoutes from './admin/AdminRoutes';
import groupRoutes from './groups/GroupRoutes';
import storeRoutes from './stores/StoreRoutes';
import EditProfileComponent from '../components/profile/EditProfileComponent';
import Profile from './profile/ProfilePage';
import EditPublicProfile from './profile/EditPublicProfilePage';
// import adminRoutes from './admin/AdminRoutes';
// import conventionRoutes from './conventions/ConventionRoutes';


const router = createBrowserRouter([
    { 
      path: '/', 
      element: <BaseLayout />,
      errorElement: <h1>404 Not Found</h1>,
      children: [ 
        {index:true, element: <HomePage/> },
        //{path:'login', element: <LoginPage/>},
        // {path:'signup', element: <SignupPage/>},
        {path:'terms-of-service', element: <TermsPage/>},
        // {path:'reset-password', element: <ResetPasswordPage/>},
        {path: 'profile', element: <Profile/>},
        // {path: 'edit-public-profile', element: <Edit_public_profile/>},
        // {path: 'edit-profile', element: <EditProfileComponent />},
        // {path:'dashboard', element: <DashboardPage/>},
        {path:'about', element: <AboutPage/>},
        {path:'contactus', element: <ContactPage/>},
        //profileRoutes,
        eventRoutes,
        gameRoutes,
        // groupRoutes,
        storeRoutes,
        adminRoutes,
        // conventionRoutes
      ]
    }
  ]);

  export default router;